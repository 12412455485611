import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/core/services/token.service';

@Injectable( {
	providedIn: 'root'
} )
export class AntaresIntercepter implements HttpInterceptor {
	constructor ( private tokenService: TokenService ) {}
	intercept ( req: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
		const auth = req.clone( {
				headers: new HttpHeaders( {
					'Authorization': `Bearer ${this.tokenService.getAuthToken()}`
				} )
			} );
		return next.handle( auth );
	}
}
